import React from 'react';
import { GrReactjs, GrAndroid, GrPersonalComputer } from "react-icons/gr";
import { GoLaw } from "react-icons/go";
import { DiJavascript, DiHtml5, DiCss3, DiJava, DiPython, DiMysql, DiMsqlServer } from "react-icons/di";
import { CiDatabase } from "react-icons/ci";
import { SiYaml, SiWordpress, SiPrestashop, SiBootstrap, SiSymfony, SiTailwindcss } from "react-icons/si";
import { FaVuejs, FaGithub, FaDocker, FaLinux, FaWindows, FaLanguage } from "react-icons/fa6";
import { FaTools } from "react-icons/fa";
import { TbBrandCpp } from "react-icons/tb";
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
const services = [
  {
    name: 'Langages',
    description: (
      <>
        <DiJavascript title='JavaScript' />,   <DiHtml5  title='HTML'/>, <DiCss3 title='CSS'/>, <SiYaml title='YAML'/>, <DiJava title='Java' />, <DiPython title='Python'/>, <TbBrandCpp title='C++'/>
      </>
    ),
    icone : <FaLanguage />
  },
  {
    name: 'Base de Données',
    description: (
      <>
        <DiMysql title='MySQL'/>, <DiMsqlServer title='SQL SERVER'/>, Transact SQL 
      </>
    ),
    icone : <CiDatabase />
  },
  {
    name: 'Outils, Framework',
    description: (
      <>
      <SiWordpress title='Wordpress'/>, <SiPrestashop title='Prestashop'/>, <SiBootstrap title='Boostrap'/>, <SiSymfony title='Symfony'/>, <FaVuejs title='Vue.JS'/>, <SiTailwindcss title='TailwindCSS'/>, <GrReactjs title='ReactJS'/> , <FaGithub title='Github'/>, <FaDocker title='Docker'/>
      </>
    ),
    icone : <FaTools />
    
  },
  {
    name: 'Plateformes',
    description: (
      <>
        <FaLinux title='Linux'/>, <FaWindows title='Windows'/>, <GrAndroid title='Android'/>
      </>
      ),
      icone : <GrPersonalComputer />
  },
  {
    name: 'Normes',
    description: 'PSR, AirbnbJS',
    icone : <GoLaw />
  },
]

const Services = () => {
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row p-6'>
          <motion.div
          variants={fadeIn('right', 0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }} 
          className='flex-1 lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0'>
            <h2 className='h2 text-accent mb-6'>Mes compétences</h2>
            <h3 className='h3 max-w-[455px] mb-16'> Voici la listes de mes compétences en informatique</h3>
            <p className='mb-16'>Tableau de synthèse des réalisations professionnelles</p>
            <a href="https://docs.google.com/spreadsheets/d/1CFM8yTI7LTLQgNqN3VZU1UPkIUm564Wn/edit?usp=sharing&ouid=101968658976475912040&rtpof=true&sd=true"  rel="noreferrer" target='_blank' className="btn btn-lg hover:scale-110 flex items-center justify-center max-w-36">
              <button className="btn-link" >Voir tableau</button>
            </a>
          </motion.div>
          <div className='flex-1'>
            <motion.div
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }} className='flex-1'>
              {services.map((service, index) => {
                const { name, description, icone } = service;
                return (
                  <div className='border-b border-white/20 h-[126px] mb-[38px] flex' key={index}>
                    <div className='lg:max-w-[476px] w-full'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>{name}</h4>
                      <p className='font-secondary leading-tight flex justify-center text-4xl gap-2'>{description}</p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <div className='btn w-9 h-9 mb-[42px] flex justify-center items-center'>
                        {icone}
                      </div>
                    </div>
                  </div>);
              })}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Services;
