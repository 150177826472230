import React from 'react';

import { motion } from 'framer-motion';

import { fadeIn } from '../variants';



const Veille = () => {
  return (
    <section className='section' id='veille'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-y-10 lg:gap-x-20 lg:gap-y-0 h-screen p-6'>
          <motion.div
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 text-center'>
            <h2 className='h2 text-accent'>Ma veille</h2>
            <h3 className='h3 mb-4'>Qu'est ce qu'une Veille informatique ?</h3>
            <p className='mb-6'>
              La veille technologique est un processus stratégique de collecte, d'analyse et de diffusion d'informations sur les avancées, les tendances et les innovations dans le domaine technologique.
              Son objectif est de permettre aux organisations de rester informées des développements pertinents, de saisir les opportunités et d'anticiper les changements dans leur environnement concurrentiel.</p>
            <h3 className='h3 mb-4'>Sujet de ma veille</h3>
            <p className='mb-6'><span className='text-gradient'>Tailwindcss </span>est un framework CSS utility-first qui simplifie et accélère le processus de conception en permettant aux développeurs de styliser leurs applications web en utilisant des classes utilitaires pré-définies.</p>
            <div className='flex justify-around gap-x-20 lg:flex-row flex-col gap-y-12'>
              <div className='flex-1 border rounded-2xl flex flex-col gap-x-6 pb-24 p-6 items-start'>
                <h3 className='h3 text-accent'>Comment je me tiens informé : </h3>
                <p>
                  Tout d'abord, j'utilise Tailwind dans la plupart de mes projets, ce qui me permet de rester informé des dernières mises à jour. De plus je suis l'actualité de Tailwind sur leur blog, je reste également attentif aux discussions et aux issues sur leur GitHub. Enfin, je suis activement la communauté de Tailwind sur les réseaux sociaux tels que Twitter et Reddit.
                </p>
              </div>
              <div className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start'>
                <h3 className='h3 text-accent'>Comment j'utilise ces données : </h3>
                <p>Cette approche me permet de rester constamment à jour et de tirer le meilleur parti de Tailwind dans mes projets de développement web.</p>
              </div>

            </div>
          </motion.div>
        </div>
      </div>
    </section >
  );
};

export default Veille;
