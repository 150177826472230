import React from 'react';
import { Link } from 'react-scroll';
const Header = () => {
  return (
    <header className='py-8' >
      <div className='container mx-auto'>
        <div className='flex items-center justify-end'>
          <Link to='contact' smooth={true}>
            <button className='btn btn-sm hover:scale-110'>Contacter moi !</button>
          </Link>
        </div>
      </div>
    </header >
  );
};

export default Header;
