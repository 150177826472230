import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";

const Contact = () => {
  return (
    <section className='selection' id='contact'>
      <motion.div
        variants={fadeIn('up', 0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        className='container mx-auto mt-[50rem] lg:mt-0'>
        <div className='flex flex-col lg:flex-row'>
          <div className='flex-1'>
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>N'hésitez pas </h4>
              <h2 className='text-4xl lg:text-7xl leading-none mb-12'>
                Contactez Moi !
              </h2>
            </div>
            <div className='flex justify-around'>
              <div className='flex gap-5'>
                <div className='btn w-11 h-11 mb-2 lg:mb-0 flex justify-center items-center'>
                  <BsFillTelephoneFill />
                </div>
                <p className='text-base lg:text-3xl flex justify-center items-center'>07 68 70 11 52</p>
              </div>
              <div className='flex gap-5'>
                <div className='btn w-11 h-11 mb-2 lg:mb-0 flex justify-center items-center'>
                  <MdOutlineEmail />
                </div>
                <p className='text-base lg:text-3xl flex justify-center items-center'>pro.abenoist@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Contact;
