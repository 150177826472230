import React from 'react';
// components
import Banner from './components/Banner';
import Header from './components/Header'; 
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Veille from './components/Veille';
import Contact from './components/Contact';

const App = () => {
  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden overflow-y-auto'>
      <Header />
      <Banner />
      <About />
      <Services />
      <Work />
      <Veille />
      <Contact />
      <div className='h-[26vh]'></div>
    </div>
  );
};

export default App; 
