import React from 'react';

import CountUp from 'react-countup';

import { useInView } from 'react-intersection-observer';

import { motion } from 'framer-motion';

import { fadeIn } from '../variants';



const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <div className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          <motion.div
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 text-center'>
            <h2 className='h2 text-accent'>A propos de moi</h2>
            <h3 className='h3 mb-4'>Je suis un étudiant en Informatique</h3>
            <p className='mb-6'>J'étudie en <span className='text-gradient'>BTS SIO</span> option SLAM (Solutions Logicielles et Applications Métiers) à l'ESUPEC (Cholet), Je suis passionné par les nouvelles technologies et par l'informatique depuis mon plus jeune age.
              J'ai décidé de me spécialiser en BTS SIO, avec une option SLAM, en raison de mon vif intérêt pour la création de logicelle et d'utilisation de technologies novatrices.
              Dans le futur, mon ambition est de devenir <span className='underline'>chef de projet informatique</span>. Ma passion pour les nouvelles technologies et mon engagement à relever les défis de manière créative et méthodique me préparent de la meilleur manière pour ce rôle. Je serai déterminé à diriger des équipes vers l'excellence, et à contribuer de manière significative au succès des projets informatiques.
            </p>
            <div className='lg:flex lg:gap-x-10 mb-12 justify-center'>
              <div className='hover:scale-110'>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={20} duration={3} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  ans
                </div>
              </div>
                <div className='hidden lg:flex gap-x-6 lg:gap-x-10 mb-12 justify-center'>
                  <div className=' border-2 p-2 rounded-lg hover:scale-110'>
                    <p>Capacité d'adpatation</p>
                  </div>
                </div>
                <div className='hidden lg:flex gap-x-6 lg:gap-x-10 mb-12 justify-center'>
                  <div className='text-[20px] border-2 p-2 rounded-lg hover:scale-110'>
                    <p>Leadership</p>
                  </div>
                </div>
                <div className='hidden lg:flex gap-x-6 lg:gap-x-10 mb-12 justify-center'>
                  <div className='text-[20px] border-2 p-2 rounded-lg hover:scale-110'>
                    <p>Autonome</p>
                  </div>
                </div>
            </div>
          </motion.div>
        </div>
      </div >
    </div >
  )
};

export default About;